import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./BookingRegistrationPage.module.css";
import { useForm } from "react-hook-form";
import useFetchCountries from "../hooks/useFetchCountries";
import useFetchCities from "../hooks/useFetchCities";
import { updateCustomerInfo } from "../services/guest";
import PersonIcon from "@mui/icons-material/Person";
import { MuiTelInput } from "mui-tel-input";
import MaleIcon from "@mui/icons-material/Male";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import { current } from "@reduxjs/toolkit";

const MyAccountPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const customerName = localStorage.getItem("customer_name");
  const customerPhone = localStorage.getItem("mobile_number");
  const customerCountry = localStorage.getItem("country");
  const customerEmail = localStorage.getItem("email");
  const customerCity = localStorage.getItem("city");
  const customerGender = localStorage.getItem("gender");
  const customerAge = localStorage.getItem("age");
  const customerNid = localStorage.getItem("nid");
  const customerImage = localStorage.getItem("customer_image"); // Profile image from localStorage

  const [fullName, setFullName] = useState(customerName); // Track full name value
  const [phoneValue, setPhoneValue] = useState(customerPhone);
  const [profileImage, setProfileImage] = useState(null); // Profile image state
  const [preview, setPreview] = useState(customerImage || "");

  const { countries } = useFetchCountries();
  const { cities } = useFetchCities(customerCountry); // Fetch cities based on country
  console.log(countries);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "YallaFalla | My Account";
    if (profileImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(profileImage);
    }
  }, [profileImage]);

  // Handle form submission

  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const goBackHome = () => {
    navigate(-1);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image")) {
      setProfileImage(file);
    } else {
      setProfileImage(null);
    }
  };
  const onSubmit = async (data) => {
    try {
      const updatedData = {
        nid: data.id || customerNid,
        first_name: fullName,
        city: data.city || customerCity,
        gender: data.gender || customerGender,
        age: data.age || customerAge,
        mobile_number: phoneValue,
        user_image: profileImage, // Send the profile image in the form
      };

      const response = await updateCustomerInfo(
        updatedData.nid,
        updatedData.city,
        updatedData.gender,
        updatedData.age,
        updatedData.mobile_number,
        updatedData.user_image,
        updatedData.first_name
      );

      console.log("API Response:", response);
      console.log(profileImage);

      // Save updated data to localStorage
      localStorage.setItem("customer_name", data.fullName);
      localStorage.setItem("mobile_number", phoneValue);
      localStorage.setItem("country", data.country);
      localStorage.setItem("city", data.city);
      localStorage.setItem("gender", data.gender);
      localStorage.setItem("age", data.age);
      localStorage.setItem("nid", data.id);
      if (preview) {
        localStorage.setItem("customer_image", preview); // Save the image preview
      }

      navigate("/logout"); // Navigate after successful update (optional)
    } catch (error) {
      console.error("Error updating customer info:", error);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("profile-image-upload").click();
  };

  return (
    <div style={{ margin: "50px 10px" }}>
      <Box
        sx={{
          width: "100%",
          padding: "20px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("MyAccount")}
        </Typography>
      </Box>

      {/* profile pic changer  */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {preview ? (
          <img
            src={preview}
            alt="Profile Preview"
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
        ) : (
          <div
            style={{
              width: "150px",
              height: "150px",
              backgroundColor: "#F2F2F2",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <PersonIcon sx={{ fontSize: "50px", color: "#757575" }} />
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id="profile-image-upload"
        />
        <button
          onClick={handleButtonClick}
          type="button"
          style={{
            backgroundColor: "#F26557",
            color: "white",
            padding: "8px 15px",
            borderRadius: "25px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {t("Change Profile Picture")}
        </button>
      </Box>

      {/* Form */}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {/* Full Name */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          {currentLanguage === "en" ? (
            <Box>
              <label>
                <PersonIcon sx={{ color: "rgba(242,101,87,76%)" }} />
              </label>
              <input
                defaultValue={customerName} // Use defaultValue instead of value
                onChange={(e) => setFullName(e.target.value)}
                type="text"
                name="fullName"
                placeholder="Full Name"
                {...register("fullName", {
                  required: { value: true, message: t("FullNameIsRequired") },
                })}
              />
            </Box>
          ) : (
            <Box className={styles.formGroup}>
              <input
                value={fullName} // Bind input to fullName state
                onChange={(e) => setFullName(e.target.value)}
                defaultValue={customerName}
                type="text"
                name="fullName"
                style={{ textAlign: "right" }} // Text alignment for Arabic
                placeholder="Full Name"
                {...register("fullName", {
                  required: { value: true, message: t("FullNameIsRequired") },
                })}
              />
              <label>
                <PersonIcon sx={{ color: "rgba(242,101,87,76%)" }} />
              </label>
            </Box>
          )}
          {errors.fullName && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.fullName.message}
              </span>
            </Box>
          )}
        </div>

        {/* Phone */}
        <div style={{ width: "100%" }}>
          <MuiTelInput
            InputProps={{
              sx: {
                borderRadius: "50px",
                border: "0px",
                borderColor: errors.phone ? "red" : "",
                paddingY: "8px",
              },
            }}
            defaultCountry="SA"
            fullWidth
            margin="normal"
            value={phoneValue}
            onChange={(value) => setPhoneValue(value)}
          />
          {errors.phone && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                  padding: "5px 10px",
                }}
              >
                {errors.phone.message}
              </span>
            </Box>
          )}
        </div>

        {/* Country */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <select
            name="country"
            className={
              currentLanguage === "ar"
                ? styles.customSelectAr
                : styles.customSelect
            } // Use the custom class
            style={{
              borderColor: errors.country ? "red" : "",
            }}
            {...register("country", {
              required: { value: true, message: t("CountryIsRequired") },
            })}
            defaultValue={customerCountry}
            onChange={(e) =>
              setValue("country", e.target.value, { shouldValidate: true })
            }
          >
            <option value="" disabled hidden>
              {t("Country")}
            </option>
            {countries.map((country) => (
              <option key={country.country_name} value={country.country_name}>
                {country.country_name}
              </option>
            ))}
          </select>
          {errors.country && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.country.message}
              </span>
            </Box>
          )}
        </div>

        {/* City and Age */}
        <div className={styles.formRow}>
          <div
            className={
              currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
            }
            style={{ flex: "0 0 60%" }}
          >
            <select
              className={
                currentLanguage === "ar"
                  ? styles.customSelectAr
                  : styles.customSelect
              }
              name="city"
              style={{
                borderColor: errors.city ? "red" : "",
              }}
              {...register("city", {
                required: { value: true, message: t("CityIsRequired") },
              })}
              defaultValue={customerCity}
            >
              <option value="" disabled hidden>
                {t("City")}
              </option>
              {cities.map((city) => (
                <option key={city.name} value={city.name}>
                  {city.city_name}
                </option>
              ))}
            </select>
            {errors.city && (
              <Box
                sx={{
                  padding: "5px 0px 0px 20px",
                }}
              >
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {errors.city.message}
                </span>
              </Box>
            )}
          </div>

          <div
            className={
              currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
            }
            style={{ flex: "0 0 35%" }}
          >
            <input
              style={{
                borderColor: errors.age ? "red" : "",
              }}
              type="number"
              name="age"
              placeholder={t("Age")}
              defaultValue={customerAge}
              {...register("age", {
                required: { value: true, message: t("AgeIsRequired") },
              })}
            />
            {errors.age && (
              <Box
                sx={{
                  padding: "5px 0px 0px 20px",
                }}
              >
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {errors.age.message}
                </span>
              </Box>
            )}
          </div>
        </div>

        {/* Gender */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <label htmlFor="gender">
            <MaleIcon sx={{ color: "rgba(242,101,87,76%)" }} />
          </label>
          <select
            className={
              currentLanguage === "ar"
                ? styles.customSelectAr
                : styles.customSelect
            }
            name="gender"
            style={{
              borderColor: errors.gender ? "red" : "",
            }}
            {...register("gender", {
              required: { value: true, message: t("GenderIsRequired") },
            })}
            defaultValue={customerGender}
          >
            <option value="" disabled hidden>
              {t("Gender")}
            </option>
            <option value="male">{t("Male")}</option>
            <option value="female">{t("Female")}</option>
            <option value="Prefer not to say">{t("PreferNotToSay")}</option>
          </select>
          {errors.gender && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.gender.message}
              </span>
            </Box>
          )}
        </div>

        {/* National ID */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <label>
            <BadgeIcon sx={{ color: "rgba(242,101,87,76%)" }} />
          </label>
          <input
            style={{
              borderColor: errors.id ? "red" : "",
            }}
            type="text"
            name="id"
            placeholder={t("NationalID")}
            defaultValue={customerNid}
            {...register("id", {
              required: { value: true, message: t("NationalIDIsRequired") },
            })}
          />
          {errors.id && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.id.message}
              </span>
            </Box>
          )}
        </div>

        {/* Email (Read-only) */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <label>
            <EmailIcon sx={{ color: "rgba(242,101,87,76%)" }} />
          </label>
          <input
            type="email"
            name="email"
            value={customerEmail}
            readOnly
            placeholder={t("Email")}
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className={styles.submitButton}>
          {t("SaveChanges")}
        </button>
      </form>
    </div>
  );
};

export default MyAccountPage;

import React from "react";
import { Avatar, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";

const UserBar = ({ customerName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user_image = localStorage.getItem("customer_image");

  const handleAvatarClick = () => {
    if (!customerName) {
      navigate("/login");
    } else {
      navigate("/logout");
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      maxHeight="15vh"
      marginTop={"15px"}
    >
      {customerName && (
        <Box
          style={{ gap: "10px", padding: "5px 10px" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <div onClick={handleAvatarClick}>
            <Avatar
              style={{ width: 75, height: 75, cursor: "pointer" }}
              alt="Remy Sharp"
              src={user_image}
            />
          </div>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">
              {t("Hi")}, {customerName}
            </Typography>
            <Typography variant="caption">
              {t("TrendingActivitiesNearby")}
            </Typography>
          </Box>
        </Box>
      )}
      {/* notification icon */}
      {/* {customerName && (
        <Box>
          <NotificationsIcon
            sx={{
              color: "#757575",
            }}
          />
        </Box>
      )} */}
    </Box>
  );
};

export default UserBar;

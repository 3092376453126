// import { FavoriteBorderOutlined } from "@mui/icons-material";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { eventLike } from "../../services/guest";

function ActivityCard({
  id,
  title,
  city,
  country,
  image,
  locationUrl,
  width,
  imageBoxHeight,
  minCardHeight,
  avgRating,
  token,
  event_id,
  isLiked,
}) {
  // handle like click
  const [isLike, setIsLike] = useState(isLiked);
  const [isLoading, setIsLoading] = useState(false);
  console.log(isLike);

  const handleLikeClick = async (e) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const response = await eventLike(event_id, token);
      console.log("is liked response", response);
      setIsLike(!isLike);
    } catch (error) {
      console.error("Error liking activity", error);
    } finally {
      setIsLoading(false);
    }
  };
  const navigation = useNavigate();
  const handleCardNavigation = () => {
    navigation(id);
  };
  const currentLanguage = localStorage.getItem("language");
  return (
    <Card
      sx={{
        // Width: 300,
        minWidth: 300,
        maxWidth: 350,
        minHeight: 250,
        maxHeight: 200,
        borderRadius: 5,
        // overflow: "hidden",
        boxShadow:
          "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
      }}
      onClick={handleCardNavigation}
    >
      {/* image */}
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          height: 150,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={image}
            alt="fishing"
            style={{
              width: "90%",
              height: "90%",
              objectFit: "cover",
              objectPosition: "center",
              border: 1,
              borderRadius: 30,
            }}
          />
        </Box>
        <IconButton
          onClick={handleLikeClick} // Handle click
          disabled={isLoading} // Disable the button while the API call is in progress
          sx={{
            color: "red",
            position: "absolute",
            top: 20,
            right: 30,
            "&:hover": {
              backgroundColor: "#f50057", // Change to your desired hover color
              color: "white",
            },
          }}
        >
          {isLike ? (
            <Box
              sx={{
                padding: "5px",
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Favorite
                sx={{
                  color: "#F26456", // Red color if liked
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                padding: "5px",
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
              }}
            >
              <FavoriteBorderOutlined />
            </Box>
          )}
        </IconButton>
      </Box>
      {/* content */}
      <CardContent
        sx={{
          padding: "8px 16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{}}>
          {/* title */}
          <Typography
            component="div"
            sx={{ fontWeight: "bold", color: "#5B9BD3", fontSize: "16px" }}
          >
            {title}
          </Typography>
          {/* location */}
          {city && country && (
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: "6px" }}
            >
              <LocationOnIcon sx={{ color: "#545454", fontSize:'18px' }} />

              <Typography sx={{ fontSize: "14px", color: "#A49B9B" }}>
                {city}, {country}
              </Typography>
            </Box>
          )}
        </Box>
        {/* rating */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "4px",
            flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
          }}
        >
          <StarIcon sx={{ fontSize: "18px", color: "#FFB400" }} />
          <Typography
            sx={{
              fontSize: "16px",
              marginLeft: "4px",
              color: "#FFB400",
              fontWeight: "light",
            }}
          >
            {avgRating.toFixed(1)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ActivityCard;

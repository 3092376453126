import React from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import ActivityImageCarousel from "../components/activityComponents/ActivityImageCarousel";
import ActivityHeader from "../components/activityComponents/ActivityHeader";
// import CardCarouselSection from "../components/CardCarouselSection";
import ActivityTestimonial from "../components/activityComponents/ActivityTestimonial";
import ActivityBookNowSection from "../components/activityComponents/ActivityBookNowSection";
import useFetchSingleEventDetails from "../hooks/useFetchSingleEventDetails";
import styles from "./SingleActivityPage.module.css";
import { useTranslation } from "react-i18next";
import RatingsReviews from "../components/RatingAndReview";

function SingleActivityPage() {
  const { t } = useTranslation();

  const { activityId } = useParams();
  const { eventDetails } = useFetchSingleEventDetails(activityId);
  console.log("single activity page is liked ", eventDetails?.is_liked);
  console.log("event details", eventDetails);

  if (!eventDetails) {
    return <div>Loading...</div>;
  }
  // const navigate = useNavigate();

  // const handleSeeAllButton = () => {
  //   navigate(`/${activityId}/rating`);
  // };

  // scroll function

  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  const images = [
    {
      id: "1",
      url: eventDetails?.image,
    },
    {
      id: "2",
      url: eventDetails?.image,
    },
    {
      id: "1",
      url: eventDetails?.image,
    },
    {
      id: "2",
      url: eventDetails?.image,
    },
  ];

  return (
    <Box>
      {/* image carousel */}
      <ActivityImageCarousel
        images={
          eventDetails.event_images.length > 0
            ? eventDetails.event_images
            : images
        }
        isLiked={eventDetails.is_liked}
        event_id={activityId}
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          overflow: "hidden",
          marginTop: "20px",
        }}
      >
        {/* activity details */}
        <ActivityHeader
          name={eventDetails?.title}
          description={eventDetails?.description}
          city={eventDetails?.address_title}
          country={eventDetails?.country}
          price={eventDetails?.price}
          locationUrl={eventDetails?.google_maps_location}
          category={eventDetails?.category}
          company={eventDetails?.company_name}
          status={t("Available")}
        />
        {/* snapshots */}
        {console.log(eventDetails?.feedbacks?.feedback_image)}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RatingsReviews
            ratingObject={eventDetails?.rating_summary}
            activityId={activityId}
          />
          <hr
            style={{
              border: "none", // Ensures no border is displayed
              width: "90%",
              height: "0", // Ensures the hr's height doesn't interfere
              borderTop: "1px solid rgba(139,139,139,50%)", // Creates a visible line
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {eventDetails?.feedbacks?.some(
            (feedback) => feedback.feedback_image
          ) && (
            <>
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                {t("singleActivityPageSnapShotsTitle")}
              </Typography>
              <div className={styles.snapshotContainer} onWheel={handleScroll}>
                {eventDetails.feedbacks.map((feedback, index) =>
                  feedback.feedback_image ? (
                    <div className={styles.snapshotItem} key={index}>
                      <img
                        className={styles.snapshotImage}
                        src={feedback.feedback_image}
                        alt="snapshot"
                      />
                    </div>
                  ) : null
                )}
              </div>
            </>
          )}
        </Box>

        {/* testimonials */}
        <Box
          sx={{
            marginBottom: "150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            {eventDetails?.feedbacks?.slice(0, 5).map((feedback, index) => (
              <ActivityTestimonial
                customerName={feedback.customer_name}
                feedback={feedback.feedback}
                rating={feedback.rating}
                key={index}
                reviewTime={feedback.formatted_review_time}
              />
            ))}
            {/* <ActivityTestimonial />
            <ActivityTestimonial /> */}
          </Box>
          <Typography
            sx={{
              marginTop: "20px",
            }}
          >
            <Link
              style={{
                color: "#667080",
              }}
              to={`/${activityId}/rating`}
            >
              {t("SeeAll")}
            </Link>
          </Typography>
        </Box>
        {/* book now */}
        <ActivityBookNowSection title={t("OrderButtonText")} />
      </Container>
    </Box>
  );
}

export default SingleActivityPage;

import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useFetchEvents from "../hooks/useFetchEvents";
import UpcomingCard from "../components/ordersComponents/UpcomingCard";
import MyFavouriteCard from "../components/Cards/MyFavouriteCard";

const MyFavouritePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLanguage = localStorage.getItem("language");
  const goBackHome = () => {
    navigate(-1);
  };
  const {events} = useFetchEvents()
  const filteredEvents = events.filter((event) => event.is_liked );
  console.log("filtered events",filteredEvents)
  console.log(events);

  useEffect(() => {
    document.title = "YallaFalla | Favorites";
  }, []);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          padding: "20px  0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("MyFavourite")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)", // Two columns
          gap: "20px", // Space between card
          paddingX:'10px'
        }}
      >
        {filteredEvents?.map((event) => (
          <Link
            key={event?.name}
            to={`/favourites/${event.name}`}
            style={{
              textDecoration: "none",
            }}
          >
            <MyFavouriteCard
              key={event.name}
              image={event.image}
              name={event.title}
              city={event.city}
              country={event.country}
              companyName={event.company_name}
              id={event.name}
              price={event.price}
            />
          </Link>
        ))}
      </Box>
    </div>
  );
};

export default MyFavouritePage;

import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import LoginIcon from "@mui/icons-material/Login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { setPassword } from "../services/api";
import { loginResponse } from "../store/userSlice";
import { useDispatch } from "react-redux";

const SetPassword = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate between routes
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showRePassword, setShowRePassword] = useState(false); // State to toggle password visibility

  const [password, setPass] = useState(""); // Initial password state
  const [re_password, setRePass] = useState(""); // Initial password state

  const [loading, setLoading] = React.useState(false); // Initial loading state
  const location = useLocation();
  const email = location.state?.email;
  console.log(email);
  const key = location.state?.key;

  const [error, setError] = useState(""); // State to toggle error message
  const [success, setSuccess] = useState(""); // State to toggle success message

  // Function to toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowRePassword = () => setShowRePassword(!showRePassword);

  // Prevent text selection on mouse down to avoid changing input type
  const handleMouseDownPassword = (event) => event.preventDefault();

  // Function to navigate back to home page
  const goBackHome = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();

  // Set the page title upon page load
  useEffect(() => {
    document.title = "YallaFalla | Set Password"; // Update document title for SEO and usability
  }, []);

  const validate = () => {
    if (password !== re_password) {
      setError("Passwords do not match");
      return false;
    }
    // Check if the password length is less than 3 characters
    if (password.length < 3) {
      // If so, set an error message asking for a password
      setError("Please, enter your account's password");
      // Return false to indicate validation failed
      return false;
    }

    // If no errors were found, clear any existing error message
    setError("");
    // Return true to indicate validation passed
    return true;
  };

  const handleSetPassword = async (event) => {
    // Prevent the form from submitting normally
    event.preventDefault();

    setLoading(true);

    // Clear any previous error messages
    setError("");

    // Clear any previous success messages
    setSuccess("");

    // Validate the input fields before proceeding
    let valid = validate();
    if (!valid) {
      setLoading(false);
      return;
    }

    try {
      await setPassword(email, key, password);

      // Display a success message to the user
      setSuccess("Password Set Successfully");

      const result = dispatch(
        loginResponse({ email: email, password: password })
      );
      if (loginResponse.fulfilled.match(result)) {
        setSuccess("You have been logged in successfully");
      }
      console.log(result);

      navigate("/");
    } catch (error) {
      // Handle any errors that occur during the login process
      // This could be due to invalid credentials, network issues, etc.
      console.error(error.message);
      setError("Couldn't set password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      {" "}
      {/* Container to center content */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="70vh"
      >
        <Typography
          variant="h5"
          gutterBottom
          alignSelf={"start"}
          sx={{
            position: "absolute",
            top: "30px", // Adjust the vertical distance from the top
            left: "16px", // Adjust the horizontal distance from the right
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            <ChevronLeftIcon></ChevronLeftIcon>
          </IconButton>
          Create Password
        </Typography>
        <Box sx={{ width: "100%" }}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
        <Box
          component="form"
          onSubmit={handleSetPassword}
          width="100%"
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {" "}
          {/* Form container */}
          {/* password */}
          <Box>
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              Password
            </label>
            <TextField
              placeholder="Enter your password"
              fullWidth
              required
              value={password}
              onChange={(event) => {
                setPass(event.target.value);
              }}
              onCopy={(event) => {
                event.preventDefault();
              }}
              type={showPassword ? "text" : "password"} // Toggle password visibility based on state
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showRePassword ? <Visibility /> : <VisibilityOff />}{" "}
                      {/* Icon changes based on password visibility */}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#F26557" }} />{" "}
                    {/* Start adornment icon */}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* confirm password */}
          <Box>
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              Re-Enter Password
            </label>
            <TextField
              placeholder="Enter your password again"
              fullWidth
              required
              value={re_password}
              onChange={(event) => {
                setRePass(event.target.value);
              }}
              onCopy={(event) => {
                event.preventDefault();
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              type={showRePassword ? "text" : "password"} // Toggle password visibility based on state
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRePassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showRePassword ? <Visibility /> : <VisibilityOff />}{" "}
                      {/* Icon changes based on password visibility */}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#F26557" }} />{" "}
                    {/* Start adornment icon */}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* submit button */}
          <LoadingButton
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              marginY: "20px",
            }}
            fullWidth
            color="primary"
            size="large"
            onClick={handleSetPassword}
            loading={loading}
            variant="contained"
            loadingPosition="start"
          >
            <span>SUBMIT</span>
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default SetPassword;
